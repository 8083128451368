export default function Carton() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.61 17.51"
      width="100%"
      height="100%"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="eggvg">
          <path
            d="M4.08,1.94a1.35,1.35,0,0,1,.16-.49,1.2,1.2,0,0,1,1-.57C6.28.78,19.71.12,19.71.12L27.4,2.9s1.58.89,1.75,1.58.48,2.43.48,2.43.93-.15.93.15V8s1,.14.93.45-.62.58-.93.69a.92.92,0,0,0-.55.82c0,.38-.58.49-.86.49a4.74,4.74,0,0,1-1.44,2.43s.07,1.21-.38,1.58a5.28,5.28,0,0,1-3.91.35s-.93,0-1-.35.18-.29-.1-.43a2.11,2.11,0,0,0-1.2,0,8.7,8.7,0,0,1-1.14.17,3.56,3.56,0,0,1-.61,1.05,3,3,0,0,1-2.19,1,7.08,7.08,0,0,1-2.1-.27s-.57.21-.8-.2a8.59,8.59,0,0,1-.49-1.07s-2.36,0-2.54.38,0,.72-.41,1.17a3.88,3.88,0,0,1-2.64,1.2c-1.21,0-2.78-.31-2.89-1A5.69,5.69,0,0,0,5,14.81c-.14-.11,0-.28-.45-.35S3,14.19,2.9,13.78a9.9,9.9,0,0,0-.27-1.55C2.46,12,.74,10,1,8.92c0,0-.86-.53-.86-1s.07-1.55.72-.91c0,0,.79-3.45,1.41-4A7.33,7.33,0,0,1,4.08,1.94Z"
            fill="#45ff7f"
            stroke="#9c17ff"
            strokeMiterlimit="1.62"
            strokeWidth="0.24"
          />
          <path
            d="M4.25,1.44A48.33,48.33,0,0,1,9.2,4.62c.48.58-.59,6.71-.59,6.71l1.45.27a36.48,36.48,0,0,0-4.16-.7c-1.85-.17-1.92.57-1.92.57L.13,8,4,11.47C3.74,11,7.65,11,8.61,11.33c.63.25,1.18.08,1.83.43s4.81,2,7.17,2.06a24.59,24.59,0,0,0,6.91-1.27c.75-.35,4.08-3.09,4.39-3.09A19.59,19.59,0,0,0,31,8.93l-2.76.92c0-.86-.82-6.57-.82-6.57L9.2,4.62"
            fill="#45ff7f"
            stroke="#b55cff"
            strokeMiterlimit="1.62"
            strokeWidth="0.24"
          />
          <path
            d="M17.26,8.09a.67.67,0,0,0,.68.41c.52,0,2.81,0,3-.28s.67-.78.42-1.18S20.36,5.11,20,4.94a2,2,0,0,0-2.19.13s0,.76-.26.94-.58,0-.55.44S17.26,8.09,17.26,8.09Z"
            fill="#b873ff"
            stroke="#c4c4c4"
            strokeMiterlimit="1.62"
            strokeWidth="0.24"
          />
        </g>
      </g>
    </svg>
  );
}
